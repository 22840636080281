import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <p className="privacy-policy-intro">
        Privacy Policy for SystemIRL
        <br /><br />
        At SystemIRL, we are committed to protecting your privacy. This privacy policy explains how we collect, use, and safeguard your personal data when you use our app.
      </p>

      <h2 className="privacy-policy-section-title">Information We Collect</h2>
      <ul className="privacy-policy-list">
        <li>
          <strong>Personal Information:</strong> When you sign up for SystemIRL, we may collect basic information such as your name, gender, weight, height, and age. This information is used solely to generate personalized workout plans and progress tracking.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect data on how the app is used to improve functionality and user experience. This data is anonymized and does not include any personally identifiable information.
        </li>
      </ul>

      <h2 className="privacy-policy-section-title">How We Use Your Data</h2>
      <ul className="privacy-policy-list">
        <li>Personal information is used to provide personalized workout plans and generate grades for muscle groups.</li>
        <li>Usage data helps us improve the app and its features.</li>
      </ul>

      <h2 className="privacy-policy-section-title">Data Sharing</h2>
      <ul className="privacy-policy-list">
        <li>We do not sell or share your personal information with third parties, except as required by law or to provide essential app services (e.g., analytics providers).</li>
      </ul>

      <h2 className="privacy-policy-section-title">Changes to This Policy</h2>
      <ul className="privacy-policy-list">
        <li>We may update this Privacy Policy from time to time. When we do, we will notify you by updating the date at the bottom of this page.</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
