import { Colors } from "./Colors";

export const GradeStructure = {
    // "F" and its variants
    "F-": { count: 1, colors: [Colors.veryWhitishGray, Colors.veryWhitishGrayDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "F": { count: 1, colors: [Colors.veryWhitishGray, Colors.veryWhitishGrayDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "F+": { count: 1, colors: [Colors.veryWhitishGray, Colors.veryWhitishGrayDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "E" and its variants
    "E-": { count: 2, colors: [Colors.lightGray, Colors.lightGrayDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "E": { count: 2, colors: [Colors.lightGray, Colors.lightGrayDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "E+": { count: 2, colors: [Colors.lightGray, Colors.lightGrayDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "D" and its variants
    "D-": { count: 3, colors: [Colors.yellowishBrown, Colors.yellowishBrownDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "D": { count: 3, colors: [Colors.yellowishBrown, Colors.yellowishBrownDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "D+": { count: 3, colors: [Colors.yellowishBrown, Colors.yellowishBrownDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "C" and its variants
    "C-": { count: 4, colors: [Colors.limeGreen, Colors.limeGreenDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "C": { count: 4, colors: [Colors.limeGreen, Colors.limeGreenDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "C+": { count: 4, colors: [Colors.limeGreen, Colors.limeGreenDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "B" and its variants
    "B-": { count: 5, colors: [Colors.neonLightBlue, Colors.neonLightBlueDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "B": { count: 5, colors: [Colors.neonLightBlue, Colors.neonLightBlueDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "B+": { count: 5, colors: [Colors.neonLightBlue, Colors.neonLightBlueDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "A" and its variants
    "A-": { count: 6, colors: [Colors.lightOrange, Colors.lightOrangeDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "A": { count: 6, colors: [Colors.lightOrange, Colors.lightOrangeDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "A+": { count: 6, colors: [Colors.lightOrange, Colors.lightOrangeDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "S" and its variants
    "S-": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "S": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },
    "S+": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 0, purpleCount: 0, redCount: 0 },

    // "SS" and its variants
    "SS-": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 1, purpleCount: 0, redCount: 0 },
    "SS": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 1, purpleCount: 0, redCount: 0 },
    "SS+": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 1, purpleCount: 0, redCount: 0 },

    // "SSS" and its variants
    "SSS-": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 2, purpleCount: 0, redCount: 0 },
    "SSS": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 2, purpleCount: 0, redCount: 0 },
    "SSS+": { count: 7, colors: [Colors.boldRed, Colors.boldRedDarker], yellowCount: 2, purpleCount: 0, redCount: 0 },

    // "SR" and its variants
    "SR-": { count: 7, colors: [Colors.yellow, Colors.yellow, Colors.orange, Colors.orange, Colors.orange, Colors.reddishOrange, Colors.reddishOrange, Colors.reddishOrange], yellowCount: 2, purpleCount: 1, redCount: 0 },
    "SR": { count: 7, colors: [Colors.yellow, Colors.yellow, Colors.orange, Colors.orange, Colors.orange, Colors.reddishOrange, Colors.reddishOrange, Colors.reddishOrange], yellowCount: 2, purpleCount: 1, redCount: 0 },
    "SR+": { count: 7, colors: [Colors.yellow, Colors.yellow, Colors.orange, Colors.orange, Colors.orange, Colors.reddishOrange, Colors.reddishOrange, Colors.reddishOrange], yellowCount: 2, purpleCount: 1, redCount: 0 },

    // "SSR" and its variants
    "SSR-": { count: 7, colors: [Colors.lightPurple, Colors.lightPurple, Colors.purple, Colors.purple, Colors.purple, Colors.darkPurple, Colors.darkPurple, Colors.darkPurple], yellowCount: 2, purpleCount: 2, redCount: 0 },
    "SSR": { count: 7, colors: [Colors.lightPurple, Colors.lightPurple, Colors.purple, Colors.purple, Colors.purple, Colors.darkPurple, Colors.darkPurple, Colors.darkPurple], yellowCount: 2, purpleCount: 2, redCount: 0 },
    "SSR+": { count: 7, colors: [Colors.lightPurple, Colors.lightPurple, Colors.purple, Colors.purple, Colors.purple, Colors.darkPurple, Colors.darkPurple, Colors.darkPurple], yellowCount: 2, purpleCount: 2, redCount: 0 },

    // "UR" and its variants
    "UR-": { count: 7, colors: [Colors.turquoise, Colors.turquoise, Colors.blue, Colors.blue, Colors.lightPurple, Colors.purple, Colors.pinkishPurple, Colors.pinkishPurple], yellowCount: 0, purpleCount: 3, redCount: 0 },
    "UR": { count: 7, colors: [Colors.turquoise, Colors.turquoise, Colors.blue, Colors.blue, Colors.lightPurple, Colors.purple, Colors.pinkishPurple, Colors.pinkishPurple], yellowCount: 0, purpleCount: 3, redCount: 0 },
    "UR+": { count: 7, colors: [Colors.turquoise, Colors.turquoise, Colors.blue, Colors.blue, Colors.lightPurple, Colors.purple, Colors.pinkishPurple, Colors.pinkishPurple], yellowCount: 0, purpleCount: 3, redCount: 0 },

    // "LR" and its variants
    "LR-": { count: 7, colors: [Colors.pinkishOrange, Colors.pinkishOrange, Colors.reddishOrange, Colors.reddishOrange, Colors.reddishOrange, Colors.pinkishRed, Colors.pinkishRed, Colors.pinkishRed], yellowCount: 0, purpleCount: 3, redCount: 1 },
    "LR": { count: 7, colors: [Colors.pinkishOrange, Colors.pinkishOrange, Colors.reddishOrange, Colors.reddishOrange, Colors.reddishOrange, Colors.pinkishRed, Colors.pinkishRed, Colors.pinkishRed], yellowCount: 0, purpleCount: 3, redCount: 1 },
    "LR+": { count: 7, colors: [Colors.pinkishOrange, Colors.pinkishOrange, Colors.reddishOrange, Colors.reddishOrange, Colors.reddishOrange, Colors.pinkishRed, Colors.pinkishRed, Colors.pinkishRed], yellowCount: 0, purpleCount: 3, redCount: 1 },

    // "MR" and its variants
    "MR-": { count: 7, colors: [Colors.pinkishPurple, Colors.reddishOrange, Colors.purple, Colors.blue, Colors.pinkishPurple, Colors.purple, Colors.purple, Colors.purple], yellowCount: 0, purpleCount: 3, redCount: 2 },
    "MR": { count: 7, colors: [Colors.pinkishPurple, Colors.reddishOrange, Colors.purple, Colors.blue, Colors.pinkishPurple, Colors.purple, Colors.purple, Colors.purple], yellowCount: 0, purpleCount: 3, redCount: 2 },
    "MR+": { count: 7, colors: [Colors.pinkishPurple, Colors.reddishOrange, Colors.purple, Colors.blue, Colors.pinkishPurple, Colors.purple, Colors.purple, Colors.purple], yellowCount: 0, purpleCount: 3, redCount: 2 },

    // "X" and its variants
    "X-": { count: 7, colors: [Colors.darkRed, Colors.bloodRed, Colors.bloodRed, Colors.darkRed, Colors.darkRed, Colors.darkRed, Colors.darkRed, Colors.darkRed], yellowCount: 0, purpleCount: 3, redCount: 3 },
    "X": { count: 7, colors: [Colors.darkRed, Colors.bloodRed, Colors.bloodRed, Colors.darkRed, Colors.darkRed, Colors.darkRed, Colors.darkRed, Colors.darkRed], yellowCount: 0, purpleCount: 3, redCount: 3 },
    "X+": { count: 7, colors: [Colors.darkRed, Colors.bloodRed, Colors.bloodRed, Colors.darkRed, Colors.darkRed, Colors.darkRed, Colors.darkRed, Colors.darkRed], yellowCount: 0, purpleCount: 3, redCount: 3 },

    // "EX" and its variants
    "EX-": { count: 7, colors: [Colors.bloodRed, Colors.bloodRed, Colors.darkRed, Colors.boldRedDarker, Colors.boldRedDarker, Colors.darkRed, Colors.bloodRed, Colors.bloodRed], yellowCount: 1, purpleCount: 2, redCount: 3 },
    "EX": { count: 7, colors: [Colors.bloodRed, Colors.bloodRed, Colors.darkRed, Colors.boldRedDarker, Colors.boldRedDarker, Colors.darkRed, Colors.bloodRed, Colors.bloodRed], yellowCount: 1, purpleCount: 2, redCount: 3 },
    "EX+": { count: 7, colors: [Colors.bloodRed, Colors.bloodRed, Colors.darkRed, Colors.boldRedDarker, Colors.boldRedDarker, Colors.darkRed, Colors.bloodRed, Colors.bloodRed], yellowCount: 1, purpleCount: 2, redCount: 3 }
};

// Helper function to retrieve grade properties based on the grade string
export function getGradeProperties(grade) {
    return GradeStructure[grade] || { count: 0, colors: [Colors.lightGray], yellowCount: 0, purpleCount: 0, redCount: 0 };
}
