import React, { useEffect } from 'react';

function CalculatorSidebarAd() {
  useEffect(() => {
    // Load Google AdSense script
    const adsbygoogle = window.adsbygoogle || [];
    adsbygoogle.push({});
  }, []);

  return (
    <div className="calculator-sidebar-ad">
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-4945146447171431"
           data-ad-slot="9816609482"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
}

export default CalculatorSidebarAd;
