import React from 'react';
import './ExerciseDetailsDisplay.css';

function ExerciseDetailsDisplay({
  exerciseName,
  exerciseType,
  currentGrade,
  currentRepsOrWeight,
  previousGrade,
  previousRepsOrWeight,
  nextGrade,
  nextRepsOrWeight,
  unit,
}) {
  const displayLabel = exerciseType === "Ratio" ? "Current Weight" : "Current Reps";
  const progress = ((currentRepsOrWeight - previousRepsOrWeight) / (nextRepsOrWeight - previousRepsOrWeight)) * 100;

  return (
    <div className="ExerciseDetailsDisplay-container">
      {/* Title with underline */}
      <div className="ExerciseDetailsDisplay-title">
        <h3>{exerciseName} ({currentGrade})</h3>
        <div className="underline"></div> {/* Separate div for underline */}
      </div>
      
      <p>{displayLabel}: {Math.round(currentRepsOrWeight)} {unit}</p>

      {/* Progress bar with labels on the same line */}
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
      </div>

      <div className="progress-section">
        <div className="grade-info left">
          <span>Prev: {previousGrade}</span>
          <span>{Math.round(previousRepsOrWeight)} {unit}</span>
        </div>
        
        <div className="grade-info right">
          <span>Next: {nextGrade}</span>
          <span>{Math.round(nextRepsOrWeight)} {unit}</span>
        </div>
      </div>
    </div>
  );
}

export default ExerciseDetailsDisplay;
