import React from 'react';
import './GradeResultDisplay.css';
import GradeRow from './GradeRow';
import ExerciseDetailsDisplay from './ExerciseDetailsDisplay';

function GradeResultDisplay({ gradeResult, exercise, exerciseType, exerciseGroup, userInput }) {
  if (!gradeResult) {
    return (
      <div className="GradeResultDisplay-container">
        <h3>No Grade Calculated</h3>
      </div>
    );
  }

  const {
    current_grade,
    next_grade,
    previous_grade,
    required_rep_next,
    required_rep_previous,
    required_lw_next,
    required_lw_previous,
  } = gradeResult;

  // Determine display values based on exercise type
  const nextRequirement = exerciseType === 'Ratio' ? required_lw_next : required_rep_next;
  const previousRequirement = exerciseType === 'Ratio' ? required_lw_previous : required_rep_previous;
  const unit = exerciseType === 'Ratio' ? 'lbs' : 'reps';

  return (
    <div className="GradeResultDisplay-container">
      <h3 className="GradeResultDisplay-title">Grade Calculation Result</h3>
      <GradeRow muscleGroup={exerciseGroup} grade={current_grade} />
      <ExerciseDetailsDisplay
        exerciseName={exercise}
        exerciseType={exerciseType}
        currentGrade={current_grade}
        currentRepsOrWeight={userInput} // Display user input as "Current"
        previousGrade={previous_grade}
        previousRepsOrWeight={previousRequirement}
        nextGrade={next_grade}
        nextRepsOrWeight={nextRequirement}
        unit={unit}
      />
    </div>
  );
}

export default GradeResultDisplay;
