// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 SystemIRL. All rights reserved.</p>
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
        <a 
          href="https://instagram.com/systemirl" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="footer-link"
        >
          Contact
        </a>
      </div>
    </footer>
  );
};

export default Footer;
