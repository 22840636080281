import React from 'react';
import './GradeRow.css';
import { getGradeProperties } from './GradeStructure';
import GradientText from './GradientText';

function GradeRow({ muscleGroup, grade }) {
    const { count, colors, yellowCount, purpleCount, redCount } = getGradeProperties(grade);

    return (
        <div className="grade-row">
            <div className="grade-row-muscle-group">{muscleGroup}</div>
            
            {/* Gradient Text with Outline and Glow */}
            <GradientText text={grade} colors={colors} />

            {/* Rectangle Gradients */}
            <div className="rectangles-container">
                <div className="rectangles-background">
                    <div className="rectangles">
                        {[...Array(7)].map((_, index) => {
                            const gradientStyle = colors.length === 2 
                                ? `linear-gradient(${colors[0]}, ${colors[1]})`
                                : `linear-gradient(to right, ${colors[index % colors.length]}, ${colors[(index + 1) % colors.length]})`;

                            return (
                                <div
                                    key={index}
                                    className={`rectangle ${index < count ? 'filled' : ''}`}
                                    style={{
                                        background: index < count ? gradientStyle : 'transparent',
                                        boxShadow: index < count ? `0 0 2px ${colors[(index + 1) % colors.length]}` : 'none',
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Extra Rectangles Container */}
            <div className="extra-rectangles-container">
                {[...Array(yellowCount)].map((_, index) => (
                    <div key={`yellow-${index}`} className="mini-rectangle yellow-mini" style={{ left: `${index * 12}px` }} />
                ))}
                {[...Array(purpleCount)].map((_, index) => (
                    <div key={`purple-${index}`} className="mini-rectangle purple-mini" style={{ left: `${index * 12}px` }} />
                ))}
                {[...Array(redCount)].map((_, index) => (
                    <div key={`red-${index}`} className="mini-rectangle red-mini" style={{ left: `${index * 12}px` }} />
                ))}
            </div>
        </div>
    );
}

export default GradeRow;
