export const Colors = {
    customNavyBlue: "rgb(0, 0, 128)",
    customRoyalBlue: "rgb(65, 105, 225)",
    customSkyBlue: "rgb(135, 206, 235)",
    customLightBlue: "rgb(173, 216, 230)",

    veryWhitishGray: "rgb(229, 229, 229)", // 0.9 * 255 = 229
    lightGray: "rgb(191, 191, 191)", // 0.75 * 255 = 191
    yellowishBrown: "rgb(204, 153, 51)", // 0.8, 0.6, 0.2 in RGB
    limeGreen: "rgb(178, 255, 0)", // 0.7, 1.0, 0.0 in RGB
    neonLightBlue: "rgb(102, 204, 255)", // 0.4, 0.8, 1.0 in RGB
    lightOrange: "rgb(255, 153, 51)", // 1.0, 0.6, 0.2 in RGB
    boldRed: "rgb(255, 0, 0)", // 1.0, 0.0, 0.0 in RGB
    yellow: "rgb(255, 255, 0)", // standard yellow
    orange: "rgb(255, 165, 0)", // standard orange
    blue: "rgb(0, 0, 255)", // standard blue
    purple: "rgb(128, 0, 128)", // standard purple
    
    veryWhitishGrayDarker: "rgb(179, 179, 179)", // 0.7 * 255 = 179
    lightGrayDarker: "rgb(128, 128, 128)", // 0.5 * 255 = 128
    yellowishBrownDarker: "rgb(128, 77, 0)", // 0.5, 0.3, 0.0 in RGB
    limeGreenDarker: "rgb(77, 204, 0)", // 0.3, 0.8, 0.0 in RGB
    neonLightBlueDarker: "rgb(26, 153, 204)", // 0.1, 0.6, 0.8 in RGB
    lightOrangeDarker: "rgb(204, 77, 0)", // 0.8, 0.3, 0.0 in RGB
    boldRedDarker: "rgb(128, 0, 0)", // 0.5, 0.0, 0.0 in RGB

    reddishOrange: "rgb(230, 102, 0)", // 0.9, 0.4, 0.0 in RGB
    lightPurple: "rgb(204, 153, 255)", // 0.8, 0.6, 1.0 in RGB
    darkPurple: "rgb(102, 0, 153)", // 0.4, 0.0, 0.6 in RGB
    turquoise: "rgb(0, 204, 204)", // 0.0, 0.8, 0.8 in RGB
    pinkishPurple: "rgb(204, 102, 204)", // 0.8, 0.4, 0.8 in RGB
    pinkishOrange: "rgb(255, 153, 102)", // 1.0, 0.6, 0.4 in RGB
    pinkishRed: "rgb(255, 51, 102)", // 1.0, 0.2, 0.4 in RGB
    darkRed: "rgb(128, 0, 0)", // 0.5, 0.0, 0.0 in RGB
    bloodRed: "rgb(179, 0, 0)", // 0.7, 0.0, 0.0 in RGB

    brightYellow: "rgb(255, 223, 0)", // bright yellow
    brightBlue: "rgb(0, 122, 255)", // bright blue
    bloodyRed: "rgb(138, 3, 3)", // blood/dark red

    customGreen: "rgb(76, 175, 80)", // custom green
    customRed: "rgb(244, 67, 54)", // custom red
};
