import React from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo and SystemIRL clickable link to Home */}
        <Link to="/" className="nav-logo">
          <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="Logo" className="logo-image" />
          <h1>SystemIRL</h1>
        </Link>
        <ul className="nav-links">
          <li><Link to="/about">About</Link></li>
          <li><Link to="/features">Features</Link></li>
          <li><Link to="/calculator">Calculator</Link></li>  {/* New Calculator tab */}
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
