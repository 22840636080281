import React, { useState, useEffect } from 'react';
import './ExerciseModal.css';
import exerciseData from '../exercise_metadata.json';

function formatExerciseName(name) {
  return name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

function ExerciseModal({ onClose, onSelectExercise }) {
  const [groupFilter, setGroupFilter] = useState('All');
  const [equipmentFilter, setEquipmentFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);

  useEffect(() => {
    const allExercises = Object.values(exerciseData).flatMap((exercises) =>
      Object.values(exercises)
    );

    const filtered = allExercises.filter((exercise) => {
      const matchesGroup = groupFilter === 'All' || exercise.Group === groupFilter;
      const matchesEquipment = equipmentFilter === 'All' || exercise.Equipment === equipmentFilter;
      const matchesSearch = exercise.Name.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesGroup && matchesEquipment && matchesSearch;
    });

    setFilteredExercises(filtered);
  }, [groupFilter, equipmentFilter, searchTerm]);

  return (
    <div className="exercise-modal-container">
      <div className="exercise-modal-content">
        <h3 className="exercise-modal-title">Select an Exercise</h3>
        <button className="exercise-modal-close" onClick={onClose}>X</button>
        
        {/* Filters */}
        <div className="exercise-modal-filters">
          <select
            value={groupFilter}
            onChange={(e) => setGroupFilter(e.target.value)}
            className="exercise-modal-dropdown"
          >
            <option value="All">All Groups</option>
            <option value="Legs">Legs</option>
            <option value="Back">Back</option>
            <option value="Shoulders">Shoulders</option>
            <option value="Biceps">Biceps</option>
            <option value="Triceps">Triceps</option>
            <option value="Core">Core</option>
            <option value="Chest">Chest</option>
            <option value="Forearms">Forearms</option>
          </select>
          <select
            value={equipmentFilter}
            onChange={(e) => setEquipmentFilter(e.target.value)}
            className="exercise-modal-dropdown"
          >
            <option value="All">All Equipment</option>
            <option value="Barbell">Barbell</option>
            <option value="Dumbbell">Dumbbell</option>
            <option value="Machine">Machine</option>
            <option value="Bodyweight">Bodyweight</option>
            <option value="Cable">Cable</option>
          </select>
        </div>

        {/* Search Input */}
        <div className="exercise-modal-search-container">
          <input
            type="text"
            placeholder="Search exercises..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="exercise-modal-search"
          />
        </div>

        {/* Exercise List */}
        <ul className="exercise-modal-list">
          {filteredExercises.map((exercise) => (
            <li
              key={exercise.Name}
              onClick={() => onSelectExercise(exercise.Name)}
              className="exercise-modal-item"
            >
              {formatExerciseName(exercise.Name)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ExerciseModal;
