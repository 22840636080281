import React, { useEffect } from 'react';

function HomeSidebarAd() {
  useEffect(() => {
    // Load the Google Adsense script after component mounts
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;
    script.setAttribute("data-ad-client", "ca-pub-4945146447171431"); // Your AdSense client ID
    document.body.appendChild(script);

    // Initialize the ad
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="ad-container">
      {/* Ad unit for Google Adsense */}
      <ins className="adsbygoogle"
           style={{ display: "block" }}
           data-ad-client="ca-pub-4945146447171431"
           data-ad-slot="7647511648"
           data-ad-format="auto"
           data-full-width-responsive="true">
      </ins>
    </div>
  );
}

export default HomeSidebarAd;
