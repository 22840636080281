// CardFlip.js
import React, { useState } from 'react';
import './CardFlip.css';
import SilverFront from '../assets/Silver_Front.jpeg';
import SilverBack from '../assets/Silver_Back.jpeg';
import PlatFront from '../assets/Plat_Front.jpeg';
import PlatBack from '../assets/Plat_Back.jpeg';
import MasterFront from '../assets/Master_Front.jpeg';
import MasterBack from '../assets/Master_Back.jpeg';
import { FaArrowRight } from 'react-icons/fa';

const CardFlip = () => {
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (e) => {
    setSliderValue(parseInt(e.target.value));
  };

  return (
    <div className="CardFlip-container">
      <div className="CardFlip-cards">
        {/* Silver Card */}
        <div className="CardFlip-card-container">
          <div className="CardFlip-card-type">Silver</div>
          <div className={`CardFlip-card ${sliderValue > 0 ? 'CardFlip-flipped' : ''}`}>
            <div className="CardFlip-card-face CardFlip-card-front">
              <img src={SilverFront} alt="Silver Front" />
              <div className="CardFlip-card-middle-text">Tons</div>
            </div>
            <div className="CardFlip-card-face CardFlip-card-back">
              <img src={SilverBack} alt="Silver Back" />
            </div>
          </div>
          <div className="CardFlip-card-grade">C</div>
        </div>

        <FaArrowRight className="CardFlip-arrow-icon" />

        {/* Platinum Card */}
        <div className="CardFlip-card-container">
          <div className="CardFlip-card-type">Platinum</div>
          <div className={`CardFlip-card ${sliderValue > 33 ? 'CardFlip-flipped' : ''}`}>
            <div className="CardFlip-card-face CardFlip-card-front">
              <img src={PlatFront} alt="Plat Front" />
              <div className="CardFlip-card-middle-text">of</div>
            </div>
            <div className="CardFlip-card-face CardFlip-card-back">
              <img src={PlatBack} alt="Plat Back" />
            </div>
          </div>
          <div className="CardFlip-card-grade">SS</div>
        </div>

        <FaArrowRight className="CardFlip-arrow-icon" />

        {/* Master Card */}
        <div className="CardFlip-card-container">
          <div className="CardFlip-card-type">Master</div>
          <div className={`CardFlip-card ${sliderValue > 66 ? 'CardFlip-flipped' : ''}`}>
            <div className="CardFlip-card-face CardFlip-card-front">
              <img src={MasterFront} alt="Master Front" />
              <div className="CardFlip-card-middle-text">Achievements</div>
            </div>
            <div className="CardFlip-card-face CardFlip-card-back">
              <img src={MasterBack} alt="Master Back" />
            </div>
          </div>
          <div className="CardFlip-card-grade">SSR</div>
        </div>
      </div>

      <input
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={handleSliderChange}
        className="CardFlip-slider"
      />
    </div>
  );
};

export default CardFlip;
