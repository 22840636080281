import React, { useEffect } from 'react';

const AboutSidebarAd = () => {
  useEffect(() => {
    // Initialize the ad unit
    if (window.adsbygoogle) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div className="about-sidebar-ad">
      <ins className="adsbygoogle"
           style={{ display: "block" }}
           data-ad-client="ca-pub-4945146447171431"
           data-ad-slot="6731281142"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
};

export default AboutSidebarAd;
