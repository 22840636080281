import React, { useState, useEffect, useCallback } from 'react';
import './Calculator.css';
import ExerciseModal from '../components/ExerciseModal';
import GradeResultDisplay from '../components/GradeResultDisplay';
import exerciseData from '../exercise_metadata.json';
import CalculatorSidebarAd from '../components/CalculatorSidebarAd'; // Import the ad component

function Calculator() {
  const [gender, setGender] = useState('Male');
  const [bodyweight, setBodyweight] = useState('');
  const [weightUnit, setWeightUnit] = useState('lb'); // Unit for bodyweight
  const [liftingWeightUnit, setLiftingWeightUnit] = useState('lb'); // Unit for lifting weight
  const [exercise, setExercise] = useState('Bench Press');
  const [selectedExerciseData, setSelectedExerciseData] = useState(null);
  const [lift, setLift] = useState('');
  const [reps, setReps] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gradeResult, setGradeResult] = useState(null); // State for grade result

  const formatExerciseName = (name) => {
    return name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const setExerciseData = useCallback((exerciseName) => {
    const formattedExercise = formatExerciseName(exerciseName);
    let exerciseInfo = null;
    for (const group of Object.values(exerciseData)) {
      if (group[formattedExercise]) {
        exerciseInfo = group[formattedExercise];
        break;
      }
    }
    setExercise(formattedExercise);
    setSelectedExerciseData(exerciseInfo);
  }, []);

  const onSelectExercise = (selectedExercise) => {
    setExerciseData(selectedExercise);
    setGradeResult(null); // Reset grade result to display "No Grade Calculated"
    setIsModalOpen(false);
  };

  useEffect(() => {
    setExerciseData('Bench Press');
  }, [setExerciseData]);

  const calculateGrade = async () => {
    const url = "https://systemirl-029f1b0ad68a.herokuapp.com/calculate_grade";
  
    const body = {
      exercise: exercise,
      gender: gender,
      bodyweight: parseFloat(bodyweight),
      reps: parseInt(reps, 10),
      type: selectedExerciseData.Type,
      bodyweight_unit: weightUnit,
      pulley_system: selectedExerciseData.Equipment === 'Cable' ? 1 : 0
    };
  
    if (selectedExerciseData.Type === "Ratio" && lift) {
      body.lifting_weight = parseFloat(lift);
      body.lifting_weight_unit = liftingWeightUnit;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log("Grade Calculation Result:", result);
      setGradeResult(result); // Set grade result data to state
  
    } catch (error) {
      console.error("Error calculating grade:", error);
    }
  };

  return (
    <div className="Calculator-outer-container">
      {/* Left Sidebar with Ads */}
      <div className="Calculator-sidebar">
        <CalculatorSidebarAd />
      </div>

      {/* Main Calculator Content */}
      <div className="Calculator-container">
        <h2 className="Calculator-title">Strength Calculator</h2>
        <form className="Calculator-form">
          {/* Gender Selection */}
          <div className="Calculator-field">
            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              className="Calculator-input"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          {/* Bodyweight Input */}
          <div className="Calculator-field">
            <label htmlFor="bodyweight">Bodyweight</label>
            <div className="Calculator-bodyweight">
              <input
                type="number"
                id="bodyweight"
                className="Calculator-input"
                value={bodyweight}
                onChange={(e) => setBodyweight(e.target.value)}
              />
              <select
                className="Calculator-input Calculator-unit"
                value={weightUnit}
                onChange={(e) => setWeightUnit(e.target.value)}
              >
                <option value="lb">lb</option>
                <option value="kg">kg</option>
              </select>
            </div>
          </div>

          {/* Exercise Selection */}
          <div className="Calculator-field">
            <label htmlFor="exercise">Exercise</label>
            <div className="Calculator-input" onClick={() => setIsModalOpen(true)}>
              {exercise}
            </div>
          </div>

          {/* Conditionally Render Fields Based on Exercise Type */}
          {selectedExerciseData && selectedExerciseData.Type === 'Ratio' ? (
            <>
              <div className="Calculator-field">
                <label htmlFor="lift">Lift</label>
                <div className="Calculator-lift">
                  <input
                    type="number"
                    id="lift"
                    className="Calculator-input"
                    value={lift}
                    onChange={(e) => setLift(e.target.value)}
                  />
                  <select
                    className="Calculator-input Calculator-unit"
                    value={liftingWeightUnit}
                    onChange={(e) => setLiftingWeightUnit(e.target.value)}
                  >
                    <option value="lb">lb</option>
                    <option value="kg">kg</option>
                  </select>
                </div>
              </div>
              <div className="Calculator-field">
                <label htmlFor="reps">Repetitions</label>
                <select
                  id="reps"
                  className="Calculator-input"
                  value={reps}
                  onChange={(e) => setReps(e.target.value)}
                >
                  {[...Array(20).keys()].map((n) => (
                    <option key={n} value={n + 1}>{n + 1}</option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            selectedExerciseData && selectedExerciseData.Type === 'Reps' && (
              <div className="Calculator-field">
                <label htmlFor="reps">Repetitions</label>
                <input
                  type="number"
                  id="reps"
                  className="Calculator-input"
                  value={reps}
                  onChange={(e) => setReps(e.target.value)}
                  min="1"
                />
              </div>
            )
          )}

          <button type="button" className="Calculator-button" onClick={calculateGrade}>
            Calculate Strength
          </button>
        </form>

        {isModalOpen && (
          <ExerciseModal onClose={() => setIsModalOpen(false)} onSelectExercise={onSelectExercise} />
        )}
      </div>

      <GradeResultDisplay
        gradeResult={gradeResult}
        exercise={exercise}
        exerciseType={selectedExerciseData?.Type} // Pass exercise type
        exerciseGroup={selectedExerciseData?.Group} // Pass exercise group
        userInput={selectedExerciseData?.Type === 'Ratio' ? lift : reps} // Pass user input based on exercise type
      />

      {/* Right Sidebar with Ads */}
      <div className="Calculator-sidebar">
        <CalculatorSidebarAd />
      </div>
    </div>
  );
}

export default Calculator;
