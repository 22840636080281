import React, { useState } from 'react';
import './AchievementCardSpinner.css';
import png1 from '../assets/Silver_Back.jpeg'; // Replace with the actual path to your images
import png2 from '../assets/Gold_Back.jpeg';
import png3 from '../assets/Plat_Back.jpeg';
import png4 from '../assets/Diamond_Back.jpeg';
import png5 from '../assets/Master_Back.jpeg';
import png6 from '../assets/Challenger_Back.jpeg';

const images = [png1, png2, png3, png4, png5, png6];

function AchievementCardSpinner() {
  const [currentIndex, setCurrentIndex] = useState(1);

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  // Get the current, previous, and next images
  const previousImage = images[(currentIndex - 1 + images.length) % images.length];
  const currentImage = images[currentIndex];
  const nextImage = images[(currentIndex + 1) % images.length];

  return (
    <div className="achievement-card-spinner">
      <button onClick={handleLeftClick} className="arrow-button left">❮</button>
      <div className="cards-container">
        <img src={previousImage} alt="Previous" className="card small" />
        <img src={currentImage} alt="Current" className="card main" />
        <img src={nextImage} alt="Next" className="card small" />
      </div>
      <button onClick={handleRightClick} className="arrow-button right">❯</button>
    </div>
  );
}

export default AchievementCardSpinner;
