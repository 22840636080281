import React, { useState, useCallback, useEffect } from 'react';
import './GradeRowSpinner.css';
import GradeRow from './GradeRow';

const grades = [
    "F", "E", "D", "C", "B", "A", "S", "SS", "SSS", "SR", "SSR", "UR", "LR", "MR", "X", "EX"
  ];
  

function GradeRowSpinner() {
  const [currentIndex, setCurrentIndex] = useState(10); // Start from middle grade
  const [lastScrollTime, setLastScrollTime] = useState(0);

  const handleScroll = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const now = Date.now();
    if (now - lastScrollTime < 200) return; // Throttle the scroll to be less sensitive
    setLastScrollTime(now);

    if (event.deltaY < 0 && currentIndex > 0) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.deltaY > 0 && currentIndex < grades.length - 1) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, grades.length - 1));
    }
  }, [currentIndex, lastScrollTime]);

  // Add an effect to disable the parent page scroll while hovering over the spinner
  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const spinnerElement = document.querySelector('.grade-row-spinner');
    spinnerElement.addEventListener('wheel', handleScroll);

    // Enable scroll locking on mouse enter, disable on mouse leave
    spinnerElement.addEventListener('mouseenter', () => {
      document.body.style.overflow = 'hidden';
    });
    spinnerElement.addEventListener('mouseleave', () => {
      document.body.style.overflow = 'auto';
    });

    // Cleanup
    return () => {
      spinnerElement.removeEventListener('wheel', handleScroll);
      spinnerElement.removeEventListener('mouseenter', disableScroll);
      spinnerElement.removeEventListener('mouseleave', disableScroll);
      document.body.style.overflow = 'auto';
    };
  }, [handleScroll]);

  const currentGrade = grades[currentIndex];
  const previousGrade = grades[currentIndex - 1] || null;
  const nextGrade = grades[currentIndex + 1] || null;

  return (
    <div className="grade-row-spinner">
      <div className="grades-container">
        {previousGrade && (
          <div className="grade-row-wrapper small">
            <GradeRow muscleGroup="Grade" grade={previousGrade} />
          </div>
        )}
        <div className="grade-row-wrapper main">
          <GradeRow muscleGroup="Grade" grade={currentGrade} />
        </div>
        {nextGrade && (
          <div className="grade-row-wrapper small">
            <GradeRow muscleGroup="Grade" grade={nextGrade} />
          </div>
        )}
      </div>
    </div>
  );
}

export default GradeRowSpinner;
