import React from "react";
import "./GradientText.css";

const GradientText = ({ text, colors }) => {
  const gradientStyle = {
    background: `linear-gradient(90deg, ${colors.join(", ")}) text`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  return (
    <span style={gradientStyle} className="gradient-text" data-text={text}>
      {text}
    </span>
  );
};

export default GradientText;
