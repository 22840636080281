import React, { useEffect } from 'react';

function FeaturesSidebarAd() {
  useEffect(() => {
    // This is required for ads to display, especially for Google AdSense
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error:", e.message);
    }
  }, []);

  return (
    <div className="features-sidebar-ad">
      {/* Google AdSense Script */}
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4945146447171431"
        crossOrigin="anonymous"
      ></script>
      
      {/* Ad Unit */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-4945146447171431"
        data-ad-slot="4651912714"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
}

export default FeaturesSidebarAd;
