import React, { useEffect } from 'react';
import './About.css';
import 'aos/dist/aos.css'; // AOS for scroll animations
import AOS from 'aos'; // AOS library for animations
import AboutProfile from '../assets/AboutProfile.png';
import AboutStats from '../assets/AboutStats.png';
import AboutQuests from '../assets/AboutQuests.png';
import AboutPlanner from '../assets/AboutPlanner.png';
import AboutAchievement from '../assets/AboutAchievement.png';
import AboutWorkout from '../assets/AboutWorkout.png';
import AboutSet from '../assets/AboutSet.png';
import CardFlip from '../components/CardFlip';
import AboutSidebarAd from '../components/AboutSidebarAd'; // Import the ad component

function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });

    const sections = document.querySelectorAll('.About-section');
    const images = document.querySelectorAll('.About-image');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    // Observer for message display pop animations
    const messageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('About-animate-pop');
        } else {
          entry.target.classList.remove('About-animate-pop');
        }
      });
    }, observerOptions);

    sections.forEach((section) => messageObserver.observe(section));

    // Observer for images with fade-up effect
    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('About-image-fade-in');
        } else {
          entry.target.classList.remove('About-image-fade-in');
        }
      });
    }, observerOptions);

    images.forEach((image, index) => {
      image.style.animationDelay = `${index * 0.15}s`; // stagger effect for fade-in
      imageObserver.observe(image);
    });

    return () => {
      sections.forEach((section) => messageObserver.unobserve(section));
      images.forEach((image) => imageObserver.unobserve(image));
    };
  }, []);

  return (
    <div className="About-container">
      {/* Left Sidebar Ads */}
      <div className="About-sidebar">
        <AboutSidebarAd />
        <AboutSidebarAd />
        <AboutSidebarAd />
      </div>
      
      <div className="About-page">
        {/* Introductory Block */}
        <div className="About-title">
          About
          <div className="About-title-underline"></div>
        </div>
        
        <section className="About-section About-intro-block" data-aos="fade-up">
          <div className="About-system-message">
            <h2 className="About-system-header">SystemIRL – Fitness Gamified</h2>
            <p>
              SystemIRL was created for those who want to make fitness fun and rewarding. Inspired by the 'system' genre of webtoons, this app brings you a game-like experience to track your fitness journey.
            </p>
          </div>

          {/* Image Row */}
          <div className="About-image-row">
            <img src={AboutProfile} alt="Profile" className="About-image" />
            <img src={AboutStats} alt="Stats" className="About-image" />
            <img src={AboutQuests} alt="Quests" className="About-image" />
            <img src={AboutPlanner} alt="Planner" className="About-image" />
            <img src={AboutAchievement} alt="Achievement" className="About-image" />
          </div>
        </section>

        {/* Mission Statement */}
        <section className="About-section About-mission-statement" data-aos="fade-up">
          <div className="About-system-message">
            <h2 className="About-system-header">Our Mission</h2>
            <p>
              Our mission is simple: make fitness an engaging quest. With detailed stats, personalized workout plans, and dynamic achievements, SystemIRL helps you level up.
            </p>
          </div>
        </section>

        {/* How It Works Section Title */}
        <section className="About-how-it-works-heading">
          <h2 className="About-how-it-works-title">How It Works</h2>
          <div className="About-how-it-works-underline"></div>
        </section>

        {/* How It Works - Receive Quests */}
        <section className="About-section About-how-it-works-section" data-aos="fade-up">
          <div className="About-system-message HIW">
            <h2 className="About-system-header">Receive Quests</h2>
            <p>Choose from pre-made workout plans or create your own.</p>
          </div>
          <img src={AboutWorkout} alt="Workout" className="About-image-HIW" />
        </section>

        {/* How It Works - Complete Sets */}
        <section className="About-section About-how-it-works-section" data-aos="fade-up">
          <div className="About-system-message HIW">
            <h2 className="About-system-header">Complete Sets</h2>
            <p>As you complete each set, log your progress and see your stats grow.</p>
          </div>
          <img src={AboutSet} alt="Set" className="About-image-HIW" />
        </section>

        {/* How It Works - Level Up */}
        <section className="About-section About-how-it-works-section" data-aos="fade-up">
          <div className="About-system-message HIW">
            <h2 className="About-system-header">Level Up</h2>
            <p>Unlock new achievements, see your grade improve, and climb the ranks!</p>
          </div>
          <CardFlip />
        </section>
      </div>

      {/* Right Sidebar Ads */}
      <div className="About-sidebar">
        <AboutSidebarAd />
        <AboutSidebarAd />
        <AboutSidebarAd />
      </div>
    </div>
  );
}

export default About;
