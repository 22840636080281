import React, { useEffect } from 'react';
import './Features.css';
import 'aos/dist/aos.css'; // AOS for scroll animations
import AOS from 'aos'; // AOS library for animations
import { Link } from 'react-router-dom';
import Profile1 from '../assets/Profile1.png';
import Profile2 from '../assets/Profile2.png';
import Stats1 from '../assets/Stats1.png';
import Stats2 from '../assets/Stats2.png';
import Quests1 from '../assets/Quests1.png';
import Quests2 from '../assets/Quests2.png';
import Planner1 from '../assets/Planner1.png';
import Planner2 from '../assets/Planner2.png';
import GradeRowSpinner from '../components/GradeRowSpinner';
import AchievementCardSpinner from '../components/AchievementCardSpinner';
import FeaturesSidebarAd from '../components/FeaturesSidebarAd'; // Import FeaturesSidebarAd component

function Features() {
  useEffect(() => {
    AOS.init({ duration: 1000 });

    const sections = document.querySelectorAll('.feature-section');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const messageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('feature-animate-pop');
        } else {
          entry.target.classList.remove('feature-animate-pop');
        }
      });
    }, observerOptions);

    sections.forEach((section) => messageObserver.observe(section));

    return () => {
      sections.forEach((section) => messageObserver.unobserve(section));
    };
  }, []);

  return (
    <div className="features-page">
      <div className="features-sidebar left-sidebar">
        <FeaturesSidebarAd />
        <FeaturesSidebarAd />
        <FeaturesSidebarAd />
      </div>

      <div className="features-content">
        {/* Title Block */}
        <div className="features-title">
          Features
          <div className="features-title-underline"></div>
        </div>

        {/* Feature Block: Profile */}
        <section className="feature-section feature-block" data-aos="fade-up">
          <div className="feature-message-display">
            <h2 className="feature-header">Profile</h2>
            <p>
              Keep track of your personal stats, modify your information, and see a detailed breakdown of your muscle group grades in the Profile section.
            </p>
          </div>
          <div className="feature-images-row">
            <img src={Profile1} alt="Profile Screen 1" className="feature-image" />
            <img src={Profile2} alt="Profile Screen 2" className="feature-image" />
          </div>
        </section>

        {/* Feature Block: Stats */}
        <section className="feature-section feature-block" data-aos="fade-up">
          <div className="feature-message-display">
            <h2 className="feature-header">Stats</h2>
            <p>
              Analyze your performance across muscle groups with real-time grading. Dive into each exercise's stats to see how close you are to leveling up. Calculate your grade <Link to="/calculator" className="calculator-link">here</Link>.
            </p>
          </div>
          <div className="feature-images-row">
            <img src={Stats1} alt="Stats Screen 1" className="feature-image" />
            <img src={Stats2} alt="Stats Screen 2" className="feature-image" />
          </div>
        </section>

        {/* Grade Row Spinner */}
        <div className="grade-rows-container">
          <h2 className="grades-title">Grades</h2>
          <div className="grades-underline"></div>
          <div className="grade-spinner-container">
            <GradeRowSpinner />
          </div>
        </div>

        {/* Feature Block: Quests */}
        <section className="feature-section feature-block" data-aos="fade-up">
          <div className="feature-message-display">
            <h2 className="feature-header">Quests</h2>
            <p>
              Generate daily quests or custom tasks, complete sets, and get instant feedback with dynamic grades.
            </p>
          </div>
          <div className="feature-images-row">
            <img src={Quests1} alt="Quests Screen 1" className="feature-image" />
            <img src={Quests2} alt="Quests Screen 2" className="feature-image" />
          </div>
        </section>

        {/* Feature Block: Planner */}
        <section className="feature-section feature-block" data-aos="fade-up">
          <div className="feature-message-display">
            <h2 className="feature-header">Planner</h2>
            <p>
              Plan your workout cycles, assign exercises to specific days, and let SystemIRL guide your fitness routine.
            </p>
          </div>
          <div className="feature-images-row">
            <img src={Planner1} alt="Planner Screen 1" className="feature-image" />
            <img src={Planner2} alt="Planner Screen 2" className="feature-image" />
          </div>
        </section>

        {/* Feature Block: Awards */}
        <section className="feature-section feature-block" data-aos="fade-up">
          <div className="feature-message-display">
            <h2 className="feature-header">Awards</h2>
            <p>
              Unlock achievement cards as you complete milestones. From Silver to Challenger, each accomplishment is a testament to your strength.
            </p>
          </div>
        </section>

        {/* Achievement Card Spinner */}
        <div className="achievement-cards-container">
          <h2 className="achievement-title">Achievements</h2>
          <div className="achievement-underline"></div>
          <AchievementCardSpinner />
        </div>
      </div>

      <div className="features-sidebar right-sidebar">
        <FeaturesSidebarAd />
        <FeaturesSidebarAd />
        <FeaturesSidebarAd />
      </div>
    </div>
  );
}

export default Features;
