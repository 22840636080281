import React, { useEffect } from 'react';
import './Home.css';
import QuestView from '../assets/QuestHomePage.png';
import StatView from '../assets/StatHomePage.png';
import AchievementView from '../assets/AchievementHomePage.png';
import HomeSidebarAd from '../components/HomeSidebarAd';

function Home() {
  useEffect(() => {
    const scrollingMessages = document.querySelectorAll('.Home-system-message');
    const scrollingImages = document.querySelectorAll('.Home-feature-image img');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('Home-animate-pop');
        } else {
          entry.target.classList.remove('Home-animate-pop');
        }
      });
    }, observerOptions);

    scrollingMessages.forEach((message) => observer.observe(message));
    scrollingImages.forEach((image) => observer.observe(image));

    return () => {
      scrollingMessages.forEach((message) => observer.unobserve(message));
      scrollingImages.forEach((image) => observer.unobserve(image));
    };
  }, []);

  return (
    <div className="Home-container">
      {/* Left Sidebar with ads */}
      <div className="Home-sidebar">
        <HomeSidebarAd />
        <HomeSidebarAd />
        <HomeSidebarAd />
      </div>

      {/* Main Content */}
      <div className="Home-main">
        <section className="Home-hero">
          <img
            src={`${process.env.PUBLIC_URL}/images/LogoTransparent.png`}
            alt="SystemIRL Logo"
            className="Home-hero-logo"
          />
          <h1 className="Home-hero-title">Welcome to SystemIRL – Transform Your Workouts Into Quests!</h1>
          <p className="Home-hero-subtext">
            A fitness app inspired by the system genre of webtoons. Take on quests, improve your stats, and unlock achievements.
          </p>
          <a href="#Jump" className="Home-cta-button">Get Started</a>
        </section>

        <div id="Jump" className="Home-JumpSpace"></div>

        <section id="app-overview" className="Home-scrolling-feature">
          <div className="Home-text-block Home-system-message">
            <h2 className="Home-system-header">User, your journey begins here.</h2>
            <p>Ready to take on fitness quests and level up your stats? With SystemIRL, workout plans become quests, and every rep helps you grow stronger!</p>
          </div>
          <div className="Home-feature-image">
            <img src={QuestView} alt="Quest Screen" />
          </div>
        </section>

        <section className="Home-scrolling-feature">
          <div className="Home-feature-image">
            <img src={StatView} alt="Stats Screen" />
          </div>
          <div className="Home-text-block Home-system-message">
            <h2 className="Home-system-header">Monitor Your Progress</h2>
            <p>Monitor your progress with detailed stats for each muscle group. Improve your back, chest, legs, and more to unlock new levels!</p>
          </div>
        </section>

        <section className="Home-scrolling-feature">
          <div className="Home-text-block Home-system-message">
            <h2 className="Home-system-header">Unlock Achievements</h2>
            <p>Complete quests and unlock achievements as you grow stronger! Will you reach Platinum or even Challenger rank?</p>
          </div>
          <div className="Home-feature-image">
            <img src={AchievementView} alt="Achievement Cards" />
          </div>
        </section>

        <section className="Home-cta-section">
          <h2>Join the System</h2>
          <a
            href="https://apps.apple.com/us/app/systemirl/id6683299598"
            className="Home-cta-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Now
          </a>
        </section>
      </div>

      {/* Right Sidebar with ads */}
      <div className="Home-sidebar">
        <HomeSidebarAd />
        <HomeSidebarAd />
        <HomeSidebarAd />
      </div>
    </div>
  );
}

export default Home;
